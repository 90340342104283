import { Branch, Environment } from './environment.model';

export const environment: Environment = {
    stripe: {
        productCatalogs: [
            {
                id: 'image',
                products: [
                    {
                        description: 'Small Plan',
                        name: 'Small',
                        credits: [{ name: 'Images', value: 5 }],
                        prices: [
                            {
                                id: 'price_1P8e55105tem7ZvHdzQxuzRM',
                                type: 'flat-rate',
                                pricePerUnit: 1.5,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'year',
                            },
                            {
                                id: 'price_1P8e3L105tem7ZvHySOEMsPB',
                                type: 'flat-rate',
                                pricePerUnit: 1,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'month',
                            },
                        ],
                    },
                    {
                        description: 'Medium Plan',
                        name: 'Medium',
                        credits: [{ name: 'Images', value: 10 }],
                        prices: [
                            {
                                id: 'price_1P8e5x105tem7ZvHW52Fefzo',
                                type: 'flat-rate',
                                pricePerUnit: 2.5,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'year',
                            },
                            {
                                id: 'price_1P8e5b105tem7ZvHEqY7tGAy',
                                type: 'flat-rate',
                                pricePerUnit: 2,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'month',
                            },
                        ],
                    },
                    {
                        description: 'Large Plan',
                        name: 'Large',
                        credits: [{ name: 'Images', value: 20 }],
                        prices: [
                            {
                                id: 'price_1P8e72105tem7ZvHlo9k6996',
                                type: 'flat-rate',
                                pricePerUnit: 3.5,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'year',
                            },
                            {
                                id: 'price_1P8e6h105tem7ZvHqhqAkrvq',
                                type: 'flat-rate',
                                pricePerUnit: 3,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'month',
                            },
                        ],
                    },
                ],
            },
            {
                id: 'crawler',
                products: [
                    {
                        description: 'Small Plan',
                        name: 'Small',
                        credits: [
                            { name: 'TopUrls', value: 50 },
                            { name: 'SubPages', value: 50 },
                            { name: 'Images', value: 50 },
                            { name: 'BadgeViews', value: 50 },
                        ],
                        prices: [
                            {
                                id: 'price_1R409y105tem7ZvHOYRqxYhj',
                                type: 'flat-rate',
                                pricePerUnit: 1.5,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'year',
                            },
                            {
                                id: 'price_1R409y105tem7ZvHfvfViq7L',
                                type: 'flat-rate',
                                pricePerUnit: 1,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'month',
                            },
                        ],
                    },
                    {
                        description: 'Medium Plan',
                        name: 'Medium',
                        credits: [
                            { name: 'TopUrls', value: 100 },
                            { name: 'SubPages', value: 100 },
                            { name: 'Images', value: 100 },
                            { name: 'BadgeViews', value: 100 },
                        ],
                        prices: [
                            {
                                id: 'price_1R408o105tem7ZvHmrTRL0G6',
                                type: 'flat-rate',
                                pricePerUnit: 2.5,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'year',
                            },
                            {
                                id: 'price_1R408o105tem7ZvHAILwIyzS',
                                type: 'flat-rate',
                                pricePerUnit: 2,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'month',
                            },
                        ],
                    },
                    {
                        description: 'Large Plan',
                        name: 'Large',
                        credits: [
                            { name: 'TopUrls', value: 200 },
                            { name: 'SubPages', value: 200 },
                            { name: 'Images', value: 200 },
                            { name: 'BadgeViews', value: 200 },
                        ],
                        prices: [
                            {
                                id: 'price_1R4078105tem7ZvH6h62mlN8',
                                type: 'flat-rate',
                                pricePerUnit: 3.5,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'year',
                            },
                            {
                                id: 'price_1R4078105tem7ZvHZRiQ2wZU',
                                type: 'flat-rate',
                                pricePerUnit: 3,
                                currency: 'EUR',
                                recurring: true,
                                interval: 'month',
                            },
                        ],
                    },
                ],
            },
        ],
    },
    branch: Branch.MAIN,
    rootUrl: 'https://backend-layonardo-dev.azurewebsites.net',
    auth0: {
        config: {
            domain: 'layonardo-dev.eu.auth0.com',
            clientId: 'qsEiv755EC9wMpMuRB6IG96UWy9gmS9S',
            authorizationParams: {
                audience: 'https://layonardo-dev',
                redirect_uri: window.location.origin,
                // screen_hint: 'signup',
            },
            httpInterceptor: {
                allowedList: [
                    {
                        uri: '*',
                    },
                ],
            },
        },
    },
};
